import "./BlogCard.scss";
import React from "react";
// import MetaTags from "react-meta-tags";
import Image from "../../../src/assets/zymoCover.jpg";
import { Link } from "react-router-dom";

function BlogCard({ blog }) {
  const slug = blog?.title
    .split("-")
    .join("_")
    .split(" ")
    .join("-")
    .split("?")
    .join("$");

  // const tags = [
  //   "<h1>",
  //   "<h2>",
  //   "<h3>",
  //   "<h4>",
  //   "<h5>",
  //   "<h6>",
  //   "<span>",

  //   "<p>",
  //   "</h1>",
  //   "</h2>",
  //   "</h3>",
  //   "</h4>",
  //   "</h5>",
  //   "</h6>",
  //   "</span>",
  //   "<br />",
  // ];
  // let blogDesc = blog.description;
  // for (let i = 0; i < tags.length; i++) {
  //   blogDesc = blogDesc.split(tags[i]).join(" ");
  // }

  return (
    <>
      <Link className="link" to={`/blog/${slug}`}>
        {/* <MetaTags> */}
          <meta
            id="meta-description"
            name="description"
            content={blog.metaDescription || blog.title}
          />
        {/* </MetaTags> */}

        <link rel="canonical" href="https://www.zymo.app/blog/" />

        <div className="blog-card-container">
          <img src={blog.cover || Image} alt={blog.altText || "cover"} />
          {blog.category ? (
            <span>{blog.category}</span>
          ) : (
            <div className="no-category"></div>
          )}
          <h3>{blog.title}</h3>
          <p>{blog.metaDescription}</p>
        </div>
      </Link>
    </>
  );
}

export default BlogCard;
