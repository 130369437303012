import "./Navbar1.module.css";
import React from "react";

import zymo from "../../assets/zymonewlogo.png";
import classes from "./Navbar1.module.css";
import ZymoLogo1 from "../../assets/ZymoFinalLogo.png";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ZymoLogo, HamburgetMenuClose, HamburgetMenuOpen } from "./Icons/Icons";

function Navbar() {
  const [click, setClick] = useState(false);

  function handleClick() {
    setClick(!click);
  }

  return (
    <nav className={classes.navbar}>
      <div className={classes.navContainer}>
        {/* <div className={classes.navLogo}>
          <span className={classes.icon}>
            {/* <ZymoLogo /> */}
            {/* <img src={zymo} alt='Zymo Logo'></img> */}
          {/* </span> */}
        {/* </div> */}
        <div className={classes.logo}>
          <div className={classes.zymoLogoLink}>
            <a href="https://www.zymo.app/">
              <img src={zymo} alt="zymologo" />
            </a>
          </div>
        </div>
    <div className='container-with-justify-center'>

        <div>
          <ul
            className={
              click
                ? `${classes.navMenu} ${classes.active}`
                : `${classes.navMenu}`
            }
          >
            <li className={classes.navItem}>
              <NavLink
                exact
                to="/"
                activeClassName={classes.active}
                className={classes.navLinks}
                onClick={handleClick}
              >
                Deals
              </NavLink>
            </li>

            <li className={classes.navItem}>
              <NavLink
                exact
                to="/"
                activeClassName={classes.active}
                className={classes.navLinks}
                onClick={handleClick}
              >
                Fleet
              </NavLink>
            </li>

            <li className={classes.navItem}>
              <NavLink
                exact
                to="/about"
                activeClassName={classes.active}
                className={classes.navLinks}
                onClick={handleClick}
              >
                About Us
              </NavLink>
            </li>

            <li className={classes.navItem}>
              <NavLink
                exact
                to="/contact"
                activeClassName={classes.active}
                className={classes.navLinks}
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>

            <li className={classes.navItem}>
              <NavLink
                exact
                to="/join-us"
                activeClassName={classes.active}
                className={classes.navLinks}
                onClick={handleClick}
              >
                Career
              </NavLink>
            </li>
          </ul>
        </div>
        </div>

        <div className={classes.navIcon} onClick={handleClick}>
          {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

          {click ? (
            <span className={classes.icon}>
              <HamburgetMenuClose />
            </span>
          ) : (
            <span className={classes.icon}>
              <HamburgetMenuOpen />{" "}
            </span>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
