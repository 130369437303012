import React from "react";
import Booking from "../components/profileContent/Booking";

import { useEffect } from "react";

function BookingPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return <Booking />;
}

export default BookingPage;
