import "./careerPage.scss";

import React from "react";
import Select from "react-select";

import { db } from "../../../src/firebase-config";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";

export function CareerPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const options = [
    { value: "Coding", label: "Coding" },
    { value: "Marketing", label: "Marketing" },
    { value: "Design", label: "Design" },
    { value: "Operations", label: "Operations" },
    { value: "Finance", label: "Finance" },
    { value: "HR", label: "HR" },
    { value: "Others", label: "Others" },
  ];
  const [values, setValues] = useState({});
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [flauntSkills, setFlauntSkills] = useState("");
  const [tabSelected, setTabSelected] = useState("internship");
  const [otherSkill, setOtherSkill] = useState("");
  const [customStipend, setCustomStipend] = useState("");
  const [salaryExpectation, setSalaryExpectation] = useState("");
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedStipendExpectation, setSelectedStipendExpectation] = useState(
    "Stipend Expectation"
  );

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const joinUsFormRef = collection(db, "joinusformdata");

  function handleInputsChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  function handleSelectChange(e) {
    setSelectedSkill(e.value);
  }

  function handleFlauntSkill(e) {
    setFlauntSkills(e.target.value);
  }

  function handleStipendSelectChange(e) {
    setSelectedStipendExpectation(e.value);
  }

  function handleSalarySelectChange(e) {
    setSalaryExpectation(e.value);
  }

  function handleCareerFormSubmit(e) {
    e.preventDefault();
    let formData = {
      ...values,
      selectedSkill,
      flauntSkills,
    };
    if (selectedSkill === "Others") {
      formData = { ...formData, selectedSkill: otherSkill };
    }
    if (tabSelected === "internship") {
      if (selectedStipendExpectation === "customStipend") {
        formData = { ...formData, selectedStipendExpectation: customStipend };
      } else {
        formData = { ...formData, selectedStipendExpectation };
      }
    } else {
      formData = { ...formData, salaryExpectation, selectedExperience };
    }

    uploadData(formData);
  }

  function handleTabSwitch(tab) {
    setSelectedSkill(null);
    setSelectedStipendExpectation(null);
    setOtherSkill(null);
    setCustomStipend(null);
    setSalaryExpectation(null);
    setSelectedExperience(null);
    setTabSelected(tab);
  }

  // async function initMailer(html) {
  //   const transporter= nodemailer.createTransport({
  //       host: 'smtp.gmail.com',
  //       port: 465,
  //       secure: true,
  //       auth: {
  //           user: 'hello@zymo.app',
  //           pass: 'vzifulvttlnnipim'
  //       }
  //   })
  //    await transporter.sendMail({
  //       from:"hello@zymo.app",
  //       to:"anupam@zymo.app",
  //       subject:"New Application Received",
  //       html:html,
  //   })
  //   console.log("Email sent")
  // }

  async function uploadData(formData) {
    if (tabSelected === "internship") {
      const {
        name,
        mobile,
        email,
        city,
        aspirations,
        selectedSkill,
        flauntSkills,
        selectedStipendExpectation,
      } = formData;

      if (
        !name ||
        !mobile ||
        !email ||
        !city ||
        !aspirations ||
        !selectedSkill ||
        !flauntSkills ||
        !selectedStipendExpectation
      ) {
        enqueueSnackbar("Please provide all the fields", { variant: "error" });
        return;
      }
      try {
        await addDoc(joinUsFormRef, {
          Role: "Internship",
          FullName: formData.name,
          PhoneNo: formData.mobile,
          Email: formData.email,
          City: formData.city,
          Aspirations: formData.aspirations,
          SelectedSkill: formData.selectedSkill,
          WhyWeNeedYou: formData.flauntSkills,
          StipendExpectation: formData.selectedStipendExpectation,
        });

        // const htmlcontent=`
        //   <div>
        //   <p>Role: Internship</p>
        //   <p>FullName: ${formData.name}</p>
        //   <p>PhoneNo: ${formData.mobile}</p>
        //   <p>Email: ${formData.email}</p>
        //   <p>City: ${formData.city}</p>
        //   <p>Aspirations: ${formData.aspirations}</p>
        //   <p>SelectedSkill: ${formData.selectedSkill}</p>
        //   <p>WhyWeNeedYou: ${formData.flauntSkills}</p>
        //   <p>StipendExpectation: ${formData.selectedStipendExpectation}</p>
        //   </div>
        // `

        // await initMailer(htmlcontent).catch(e=>console.log(e));

        enqueueSnackbar("Form Submitted Successfully", { variant: "success" });
        navigate("/");
      } catch (err) {
        console.log("Error in CareerPage is: " + err);
      }
    }

    if (tabSelected === "fulltime") {
      const {
        name,
        mobile,
        email,
        city,
        aspirations,
        selectedSkill,
        flauntSkills,
        selectedExperience,
        salaryExpectation,
      } = formData;

      if (
        !name ||
        !mobile ||
        !email ||
        !city ||
        !aspirations ||
        !selectedSkill ||
        !flauntSkills ||
        !selectedExperience ||
        !salaryExpectation
      ) {
        enqueueSnackbar("Please provide all the fields", { variant: "error" });
        return;
      }

      try {
        await addDoc(joinUsFormRef, {
          Role: "Full Time",
          FullName: formData.name,
          PhoneNo: formData.mobile,
          Email: formData.email,
          City: formData.city,
          Aspirations: formData.aspirations,
          SelectedSkill: formData.selectedSkill,
          WhyWeNeedYou: formData.flauntSkills,
          Experience: formData.selectedExperience,
          SalaryExpectation: formData.salaryExpectation,
        });

        // --------------VERSION ISSUE - CODE COMMENTED-----------------
        // const htmlcontent=`
        //   <div>
        //   <p>Role: Fulltime</p>
        //   <p>FullName: ${formData.name}</p>
        //   <p>PhoneNo: ${formData.mobile}</p>
        //   <p>Email: ${formData.email}</p>
        //   <p>City: ${formData.city}</p>
        //   <p>Aspirations: ${formData.aspirations}</p>
        //   <p>SelectedSkill: ${formData.selectedSkill}</p>
        //   <p>WhyWeNeedYou: ${formData.flauntSkills}</p>
        //   <p>Experience: ${formData.selectedExperience}</p>
        //   <p>SalaryExpectation: ${formData.salaryExpectation}</p>
        //   </div>
        // `
        // await initMailer(htmlcontent).catch(e=>console.log(e));

        enqueueSnackbar("Form Submitted Successfully", { variant: "success" });
        navigate("/");
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <div className="career-form-container">
      <h1>
        <span>“</span>Join Us<span>”</span>
      </h1>

      <form className="career-page-form" onSubmit={handleCareerFormSubmit}>
        <div className="choose-adventure-container">
          <h2 className="adventure-heading">Choose your adventure.</h2>
          <div>
            {" "}
            <div
              className={
                tabSelected === "internship"
                  ? "selected-button tab-button"
                  : "tab-button"
              }
              onClick={() => handleTabSwitch("internship")}
            >
              Internship
            </div>
            <div
              className={
                tabSelected === "fulltime"
                  ? "selected-button tab-button"
                  : "tab-button"
              }
              onClick={() => handleTabSwitch("fulltime")}
            >
              Full time
            </div>
          </div>
        </div>

        <div className="personal-info-container">
          <h2> Fill in your details like a true rockstar</h2>

          <TextField
            variant="standard"
            name="name"
            onChange={handleInputsChange}
            value={values.name}
            label="Full Name"
            className="text-field"
          />
          <TextField
            variant="standard"
            name="mobile"
            onChange={handleInputsChange}
            value={values.mobile}
            label="Phone No"
            className="text-field"
          />
          <TextField
            variant="standard"
            name="email"
            onChange={handleInputsChange}
            value={values.email}
            label="Email"
            className="text-field"
          />
          <TextField
            variant="standard"
            name="city"
            onChange={handleInputsChange}
            value={values.city}
            label="City"
            className="text-field"
          />
          <TextField
            variant="standard"
            name="aspirations"
            onChange={handleInputsChange}
            value={values.aspirations}
            label="Aspirations"
            className="text-field"
          />
        </div>

        <div className="choose-skills-container">
          <h2>Pick your superpower from the dropdown</h2>
          <div className="skills-options">
            <Select
              value={selectedSkill}
              onChange={handleSelectChange}
              options={options}
              placeholder={selectedSkill || "Select Skill"}
              isSearchable
            />
          </div>
          {selectedSkill === "Others" && (
            <TextField
              variant="standard"
              name="otherSkill"
              onChange={(e) => setOtherSkill(e.target.value)}
              label="Enter Your Skills"
              className="text-field"
            />
          )}
        </div>

        <div className="flaunt-skills-container">
          <h2>
            Flaunt your skills in the "Tell us why we need you" box—impress us
            with your flair!
          </h2>
        </div>

        <textarea
          style={{
            height: ["10rem"],
            width: ["70%"],
            fontSize: ["1rem"],
          }}
          onChange={handleFlauntSkill}
        ></textarea>

        {tabSelected === "internship" && (
          <div className="stipend-expectation-container">
            <h2>Enter your stipend expectation</h2>
            <div className="stipend-expectation">
              <Select
                value={selectedStipendExpectation}
                onChange={handleStipendSelectChange}
                options={[
                  {
                    value: "I want to be paid in experience",
                    label: "I want to be paid in experience",
                  },
                  {
                    value: "customStipend",
                    label: "Enter expected stipend",
                  },
                ]}
                placeholder={
                  selectedStipendExpectation === "customStipend"
                    ? "Enter expected stipend"
                    : selectedStipendExpectation
                }
                isSearchable
              />
            </div>
            {selectedStipendExpectation === "customStipend" && (
              <TextField
                variant="standard"
                name="customStipend"
                onChange={(e) => setCustomStipend(e.target.value)}
                label="Enter Your Expected Stipend"
                className="text-field-stipend"
              />
            )}
          </div>
        )}

        {tabSelected === "fulltime" && (
          <div className="stipend-expectation-container">
            <h2>Select experience</h2>
            <div className="stipend-expectation">
              <Select
                value={selectedExperience}
                onChange={(e) => setSelectedExperience(e.value)}
                options={[
                  {
                    value: "0to2",
                    label: "0-2 Years",
                  },
                  {
                    value: "2to5",
                    label: "2-5 Years",
                  },
                  {
                    value: "5-10 Years ",
                    label: "5-10 Years",
                  },
                  {
                    value: "10+",
                    label: "10+ Years",
                  },
                ]}
                placeholder={selectedExperience || "Select experience"}
                isSearchable
              />
            </div>
            {selectedStipendExpectation === "customStipend" && (
              <TextField
                variant="standard"
                name="customStipend"
                onChange={(e) => setCustomStipend(e.target.value)}
                label="Enter Your Expected Stipend"
                className="text-field-stipend"
              />
            )}
          </div>
        )}

        {tabSelected === "fulltime" && (
          <div className="stipend-expectation-container">
            <h2>Enter your salary expectations</h2>

            <TextField
              variant="standard"
              name="salaryExpectation"
              onChange={(e) => setSalaryExpectation(e.target.value)}
              label="Salary Expectations"
              className="text-field-stipend"
            />
          </div>
        )}

        <div className="submit-career-form">
          <h2>
            Hit that submit button like a boss and cross your fingers for a
            reply!
          </h2>
          <button>Submit</button>
        </div>
      </form>
    </div>
  );
}

/*
Error: Facing dependencies and package related issues while attempting to load nodemailer and send mails when receiving new applications in this form.

Code to implement the same has been commented out in this file above

If planning on changing the code, the necessary nodemailer authentication details can be obtained from the initMailer function (commented out) and used subsequently. 
*/
