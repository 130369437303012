import "./cardSkeleton.scss";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

export function CardSkeleton({ cards }) {
  return Array(cards)
    .fill(0)
    .map((item) => (
      <div className="card-skeleton-container">
        {" "}
        <div className="left-col">
          <Skeleton height={300} />
        </div>
        <div className="right-col">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    ));
}
