import React from "react";
import Benefits from "../components/about/Benefits";
import Brands from "../components/about/Brands";
import Stat from "../components/about/Stat";
import HeroSection from "../components/carousel/HeroSection";
import TestimonialCarousel from "../components/carousel/TestimonialCarousel";
import Slider from "../components/slider/Slider";
import Youtube from "../components/youtube/Youtube";
import FAQ from "../components/faq/Faq";
import FAQ1 from "../components/faq/Faq1";
import AppDownloa from "../components/UI/AppDownloa";
import ChatIcon from "../components/chatIcon/chatIcon";

import { useEffect } from "react";
import { FeaturedSectionCaraousel } from "../components/carousel/FeaturedSectionCaraousel";
import { HomeAboutUs } from "../components/homeAboutUs/HomeAboutsUs";
import { Helmet } from "react-helmet";
import WhatsAppIcon from "../components/whatsappIcon/whatsapp";
import HeroSection1 from "../components/carousel/HeroSection1";

// import BlogSection from "../components/about/BlogSection";
// import Vehicles from "../components/about/Vehicles";
// import Locations from "../components/carsBookingComponent/Locations";
// import { DateTimePicker } from "../components/carsBookingComponent/DateandTimePicker";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://zymo.app/" />
        <meta
          name="keywords"
          content="Rent Self Drive Car, Car Rental App, Self Drive Car Rental, Car Rental App India, Car Rental Apps in India, Self Drive Car Rental in Pune, Self Drive Car Rental Delhi, Self Drive Cars in Bangalore, Self Drive Car in Mumbai, Self Drive Car Rental in Chennai"
        />
      </Helmet>

      {/* <ChatIcon /> */}
      <WhatsAppIcon />

      {/*Carousel Section - Currently working on its responsiveness. */}
      <HeroSection />
      {/* <HeroSection1 /> */}

      {/* <DateTimePicker /> */}
      <Stat />

      {/* Deals */}
      <div id="deals">
        <Benefits />
      </div>

      <AppDownloa />

      {/* Cars Carousel */}
      <div id="fleet">
        <Slider />
      </div>

      {/* <Vehicles /> */}
      <FeaturedSectionCaraousel />

      {/* {Featured Section} */}
      <div id="about-us">
        <TestimonialCarousel />
      </div>

      {/* <BlogSection /> */}
      <Brands />

      {/* Youtube */}
      <Youtube />

      {/* FAQ */}
      <div id="faq">
        <FAQ1 />
      </div>

      {/* About us */}
      <HomeAboutUs />
    </div>
  );
}

export default Home;
