import React from "react";
import classes from "./TestimonialCard.module.css";
import { FaStar } from "react-icons/fa";

function TestimonialCard(props) {
  return (
    <div className={classes.testimonialCard}>
      <div className={classes.userProfile}>
        <img src={props.profileImg} alt="prfimg" loading="lazy" />
        <p>{props.name}</p>
      </div>

      <div className={classes.ratingDate}>
        {[...Array(props.numStar)].map((e, i) => (
          <FaStar key={i} className={classes.star} />
        ))}
        <p>{props.date}</p>
      </div>

      <div className={classes.feedback}>
        <p>{props.feedback}</p>
      </div>
    </div>
  );
}

export default TestimonialCard;
