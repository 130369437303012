import React from "react";
import check from "../../assets/check.png";
import styles from "./PaymentSuccess.module.css";
import { useNavigate } from "react-router-dom";

function PaymentSuccessPage() {
  const navigate = useNavigate();

  function handlePrint() {
    window.print();
  }

  // Handle closing the payment success page
  function handleClose() {
    navigate("/");
  }

  return (
    <div className={styles.PaymentSuccessPage}>
      <div className={styles.PaymentSuccessPageContent}>
        <h1 className={styles.PaymentSuccessPageHeading}>
          Payment Successful!!
        </h1>

        <div className={styles.iconContainer}>
          <img
            src={check}
            alt="Payment Success Icon"
            className={styles.PaymentSuccessPageIcon}
          />
        </div>

        <p className={styles.PaymentSuccessPageMessage}>
          Thank you for your purchase. Your payment has been successfully
          processed.
        </p>

        <div className={styles.PaymentSuccessPageButtons}>
          <button
            className={styles.PaymentSuccessPageButton}
            onClick={handlePrint}
          >
            Print
          </button>
          <button
            className={styles.PaymentSuccessPageButton}
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccessPage;
