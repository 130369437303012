import React from "react";
import cross from "../../assets/cross.png";
import styles from "./PaymentFail.module.css";
import { useNavigate } from "react-router-dom";

function PaymentFailPage() {
  const navigate = useNavigate();

  // Handle going back to the previous page
  function handleGoBack() {
    navigate(-1);
  }

  // Handle closing the payment failure page
  function handleClose() {
    navigate("/");
  }

  return (
    <div className={styles.PaymentFailPage}>
      <div className={styles.PaymentFailPageContent}>
        <h1 className={styles.PaymentFailPageHeading}>Payment Failed</h1>

        <div className={styles.iconContainer}>
          <img
            src={cross}
            alt="Payment Failure Icon"
            className={styles.PaymentFailPageIcon}
          />
        </div>

        <p className={styles.PaymentFailPageMessage}>
          Sorry, your payment was not successful. Please try again.
        </p>

        <div className={styles.PaymentFailPageButtons}>
          <button
            className={styles.PaymentFailPageButton}
            onClick={handleGoBack}
          >
            Go Back
          </button>
          <button
            className={styles.PaymentFailPageButton}
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentFailPage;

// import React, { useState } from "react";
// import PaymentSuccessPage from "../../pages/PaymentSuccessPage";
// import PaymentFailPage from "../../pages/PaymentFailPage";

// function PaymentForm(props) {
//   const [paymentError, setPaymentError] = useState("");

//   async function handlePayment(event) {
//     event.preventDefault();
//     // Code for processing payment goes here
//     // const paymentResult = await processPayment();
//     if (PaymentSuccessPage.success) {
//       // Code for handling successful payment goes here
//       PaymentSuccessPage()
//     } else {
//       setPaymentError(PaymentFailPage.error);
//     }
//   };

//   return (
//     <form onSubmit={handlePayment}>
//       <h2>Payment Form</h2>
//       {paymentError && <p style={{ color: "red" }}>{paymentError}</p>}
//       <label htmlFor="cardNumber">Card Number</label>
//       <input type="text" id="cardNumber" required />
//       <label htmlFor="expiryDate">Expiry Date</label>
//       <input type="text" id="expiryDate" required />
//       <label htmlFor="cvv">CVV</label>
//       <input type="text" id="cvv" required />
//       <button type="submit">Submit Payment</button>
//     </form>
//   );
// }

// export default PaymentForm;
