import React from "react";
import classes from "./Navbar.module.css";
import zymo from "../../assets/zymonewlogo.png";
import { Link, useNavigate } from "react-router-dom";
import { Fragment } from "react";

function Navbar() {
  const navigate = useNavigate();

  return (
    <Fragment>
      <nav className={classes.navbar}>
        
        <div className={classes.logo}>
          <div className={classes.zymoLogoLink}>
            <a href="https://www.zymo.app/">
              <img src={zymo} alt="zymologo" />
            </a>
          </div>
        </div>

        <div className={classes.middle}>
          <p
            onClick={() => {
              navigate("/");
            }}
          >
            Deals
          </p>

          <p
            onClick={() => {
              navigate("/");
              const anchor = document.querySelector("#fleet");
              anchor.scrollIntoView({ behavior: "smooth", block: "center" });
            }}
          >
            Fleet
          </p>

          <p
            onClick={() => {
              navigate("/about");
            }}
          >
            About Us
          </p>

          <p
            onClick={() => {
              navigate("/contact");
            }}
          >
            Contact Us
          </p>
        </div>

        {/* <div className={classes.joinUsLinkContainer}>
          <Link className={classes.joinUsLink} to="/join-us">
            <p>Careers</p>
          </Link>
        </div> */}

        {/* <div className={classes.menu}>
          <div className={`${classes.menuItem} ${classes.locationMenu}`}>
            <GoLocation />
            <div className={classes.location}>
              <BsDot className={classes.icon} />
              <input
                type="text"
                className={classes.locationInput}
                placeholder="Current Location"
              />
              <AiOutlineAim className={classes.icon} />
            </div>
          </div>
          <div className={classes.menuItem}>
            <Dropdown />
          </div>
        </div> */}
      </nav>
    </Fragment>
  );
}

export default Navbar;
