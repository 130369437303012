import "./carDetailsPage.scss";

import React from "react";
import dayjs from "dayjs";
import dateFormat from "dateformat";

import { useEffect, useState, useContext } from "react";
import { TextField } from "@mui/material";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { setDoc, collection, doc } from "firebase/firestore";
import { storage, db } from "../firebase-config";
import { useSnackbar } from "notistack";
import { SelectedCarContext } from "../context/selectedCar-context";
import { BookingDateTimeContext } from "../context/bookingDateTime-context";
import { UserDetailsContext } from "../context/userDetails-context";
// import { MdDriveEta } from "react-icons/md";
// import { Button } from "@mui/material";

export function CarDetailPage() {
  const navigate = useNavigate();
  const startdate = dateFormat(bookingDateTime?.StartDate, " dS mmmm, yyyy");
  const enddate = dateFormat(bookingDateTime?.EndDate, "dS mmmm, yyyy");

  const [dob, setDob] = useState(dayjs(new Date()));
  const [adharImage, setAadharImage] = useState(null);
  const [licenseImage, setLicenseImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const { userDetails, setUserDetails } = useContext(UserDetailsContext);
  const { selectedCar, setSelectedCar } = useContext(SelectedCarContext);
  const { bookingDateTime, setBookingDateTime } = useContext(
    BookingDateTimeContext
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      Object.keys(selectedCar).length !== 0 &&
      Object.keys(bookingDateTime).length !== 0
    ) {
      localStorage.setItem("selectedCar", JSON.stringify(selectedCar));
      localStorage.setItem("bookingDateTime", JSON.stringify(bookingDateTime));
    } else {
      setSelectedCar(JSON.parse(localStorage.getItem("selectedCar")));
      setBookingDateTime(JSON.parse(localStorage.getItem("bookingDateTime")));
    }
  }, [selectedCar, bookingDateTime]);

  function handleClick() {
    setLoading(true);
    let dateOfBirth = dob.toISOString().split("T")[0];
    let currentDate = dayjs(new Date()).toISOString().split("T")[0];
    if (dateOfBirth === currentDate) {
      enqueueSnackbar("Please choose your date of birth", { variant: "error" });
      setLoading(false);
      return;
    }

    uploadAadhar(dateOfBirth);
  }

  function uploadAadhar(dateOfBirth) {
    if (adharImage == null) {
      enqueueSnackbar("Please upload your aadhar", { variant: "error" });
      setLoading(false);
      return;
    }

    const aadharImageref = ref(
      storage,
      `aadharImage/${adharImage.name + v4()}`
    );

    uploadBytes(aadharImageref, adharImage)
      .then((res) => {
        let url = getDownloadURL(res.ref);

        return url;
      })
      .then((url) => {
        // setAadharUrl(url);
        uploadLicense(dateOfBirth, url);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function uploadLicense(dateOfBirth, aadharUrl) {
    if (licenseImage == null) {
      enqueueSnackbar("Please upload your license", { variant: "error" });
      setLoading(false);
      return;
    }

    const licenseImageRef = ref(
      storage,
      `licenseImages/${licenseImage.name + v4()}`
    );

    uploadBytes(licenseImageRef, licenseImage)
      .then((res) => {
        let url = getDownloadURL(res.ref);
        return url;
      })
      .then((url) => {
        // setLicenseUrl(url);
        uploadUserData(dateOfBirth, aadharUrl, url);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // const userDetailsDbRef = collection(db, "userdetails");
  async function uploadUserData(dateOfBirth, aadharUrl, licenseUrl) {
    try {
      const newDocRef = doc(collection(db, "userdetails"));

      await setDoc(newDocRef, {
        userId: newDocRef.id,
        name: userDetails.Name,
        email: userDetails.Email,
        phone: userDetails.Phone,
        dob: dateOfBirth,
        aadhar: aadharUrl,
        license: licenseUrl,
      });

      enqueueSnackbar("Details submitted Successfully", { variant: "success" });
      navigate("/dailyrentals/cardetails/car-booking-summary");
    } catch (err) {
      console.log(err);
    }
  }

  function handleChange(e) {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  }

  return (
    <div className="car-details-main-container">
      <div className="car-details-container">
        <div className="car-image">
          <img
            src={selectedCar.imageurl || selectedCar.VehicleBrandImageName}
            alt="selected-car"
          ></img>
        </div>

        <div className="car-brief-intro-container">
          <ul>
            <li>{selectedCar.transmission || selectedCar.TransMissionType}</li>
            <li>{selectedCar.fuel || selectedCar.FuelType}</li>
            <li>Fullfilled by {selectedCar.vendor || "MyChoize"}</li>
          </ul>
          <h2>{selectedCar.name || selectedCar.BrandName}</h2>
        </div>

        <div className="dateTimePrice-details-container">
          <div>
            {" "}
            <h3>Start Date</h3>
            <p>
              {startdate} {bookingDateTime?.StartTime}
            </p>
            <h3>End Date</h3>
            <p>
              {enddate} {bookingDateTime?.EndTime}
            </p>
          </div>
          <div>
            <h3>Payable Amount</h3>
            <h3>₹ {selectedCar.totalRent || selectedCar.TotalExpCharge}</h3>
          </div>
        </div>

        <div className="pickup-details-container">
          <h3>Pick/Drop Location</h3>
          <p>{selectedCar["Pick/Drop Location"]}</p>
        </div>

        <div className="cancellation-policy-container">
          <h3>Cancellation Policy</h3>
        </div>
      </div>

      <div className="user-details-form-container">
        <h2>Enter Contact Details</h2>
        <form>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            name="Name"
            value={userDetails.Name}
            onChange={handleChange}
          />
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            name="Email"
            value={userDetails.Email}
            onChange={handleChange}
          />
          <TextField
            id="outlined-basic"
            label="Phone +91 "
            variant="outlined"
            name="Phone"
            value={userDetails.Phone}
            onChange={handleChange}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth"
              value={dob}
              onChange={(newDob) => setDob(newDob)}
            />
          </LocalizationProvider>
          <h4>Upload your aadhar:</h4>
          <TextField
            type="file"
            variant="outlined"
            onChange={(e) => setAadharImage(e.target.files[0])}
          />
          <h4>Upload your license:</h4>
          <TextField
            type="file"
            variant="outlined"
            onChange={(e) => setLicenseImage(e.target.files[0])}
          />
        </form>
        <p>
          By clicking on proceed I agree with{" "}
          <Link to="/terms" className="link">
            <span
              style={{
                color: "blue",
                fontSize: "medium",
                fontWeight: "600",
                textDecoration: "underline",
              }}
            >
              {" "}
              Zymo terms and conditions{" "}
            </span>
          </Link>{" "}
          Thank you for trusting our service.
        </p>{" "}
        {!loading ? (
          <button className="proceed-button" onClick={handleClick}>
            Proceed
          </button>
        ) : (
          <button className="loading-button" disabled>
            Submitting...
          </button>
        )}
      </div>
    </div>
  );
}
