import "./homeAboutUs.scss";

export function HomeAboutUs() {
  return (
    <div className="home-about-us-container">
      <h2> Rent Self Drive Cars with the Best Car Rental App in India</h2>
      <br />

      <p>
        Are you tired of relying on public transportation or borrowing cars from
        friends and family? Look no further than the best car rental app in
        India - Zymo! Our self drive car rental service is available in Pune,
        Delhi, Mumbai, Bangalore, Chennai and other major cities.
      </p>
      <br />

      <p>
        With Zymo, you can easily rent a self drive car and enjoy the freedom to
        explore your destination at your own pace. Our user-friendly app makes
        it easy to find and book the perfect car for your needs. Plus, our
        competitive prices and flexible rental options make us the best car
        rental sharing app in India
      </p>
      <br />

      <p>
        Say goodbye to the hassle of traditional car rental companies and hello
        to the convenience of Zymo's car rental application. With a range of
        options to choose from, you'll find the perfect self drive car hire for
        your next adventure.
      </p>

      <br />
      <p>
        Don't let transportation woes hold you back. Download Zymo today and
        experience the joy of driving your own self drive car rental.
      </p>
    </div>
  );
}
