import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FeaturedSectionCaraousel.scss";
import React from "react";
import Slider from "react-slick";
import { db } from "../../../src/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export function FeaturedSectionCaraousel() {
  const featurednewsRef = collection(db, "featurednews");
  const [featurednews, setFeaturedNews] = useState([]);

  async function getFeaturedNews() {
    try {
      const data = await getDocs(featurednewsRef);
      const filteredNewsData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.data,
      }));

      setFeaturedNews(filteredNewsData);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getFeaturedNews();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    pauseOnHover: true,
    appendDots: (dots) => (
      <div
        style={{
          padding: "5px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: () => (
      <div
        style={{
          height: "8px",
          backgroundColor: "grey",
          width: "20px",
          borderRadius: "40px",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="featured-container">
      <h2> Featured In</h2>

      <Slider {...settings}>
        {featurednews.map((news, idx) => {
          return (
            <div className="card" key={idx}>
              <Link to={news.newsLink}>
                {" "}
                <img src={news.image} alt="news-feature" />{" "}
              </Link>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
