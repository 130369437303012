import "./Youtube.css";
import React from "react";

function YouTubeVideo() {
  return (
    <div className="youtube-container">
      <h2>YouTube Video</h2>
      
      <iframe
        width="640"
        height="390"
        src="https://www.youtube.com/embed/videoseries?list=PLXVSl4ATFd6ZWYy-YnrGvx29t8ZO5kkmK"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default YouTubeVideo;
