import "./chatIcon.css";
import React, { useState } from "react";
import ChatWindow from "./ChatWindow";
import robotIcon from "./robot_icon.png";

function ChatIcon() {
  const [isOpen, setIsOpen] = useState(false);

  function toggleChatWindow() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="chat-icon" onClick={toggleChatWindow}>
      <img src={robotIcon} alt="chat-icon" />
      {isOpen && <ChatWindow onClose={toggleChatWindow} />}
    </div>
  );
}

export default ChatIcon;
