import "./Faq1.css";
import React from "react";
import faqData from "./faqData";
import { useState } from "react";
import toast from "react-hot-toast";

function FAQ() {
  const [selectedAccordion, setSelectedAccordion] = useState(null);
  const [email, setEmail] = useState("");

  function handleAccordionChange(id) {
    setSelectedAccordion(selectedAccordion === id ? null : id);
  }

  function handleEmail(e) {
    setEmail(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    toast.success(
      "Thank you for subscribing! Stay tuned for the latest updates and exclusive content."
    );
  }

  return (
    <div className="faq">
      <h2>Frequently Asked Questions</h2>
      <ul className="accordion">
        {faqData.map((item, index) => (
          <li key={index}>
            <input
              type="radio"
              name="accordion"
              id={index}
              checked={selectedAccordion === index}
              onChange={() => handleAccordionChange(index)}
            />
            <label htmlFor={index}>{item.question}</label>
            <div className="content">
              {selectedAccordion === index && <p>{item.answer}</p>}
            </div>
          </li>
        ))}

        <small>
          Enter your email for the latest updates.
        </small>

        <form className="email-signup" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleEmail}
            required
          />
          <button type="submit">Get Started</button>
        </form>
      </ul>
    </div>
  );
}

export default FAQ;
