import React from "react";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";

function Footer() {
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <div className={classes.firstdiv}>
          {/* <div className={classes.vehicle}>
              <h2> Vehicle </h2>
              <Link to={'/cars'}>
                <span>Cars</span>
              </Link>
              <Link to={'/cars'}>
                <span>Cars</span>
              </Link>
              <Link to={'/cars'}>
                <span>Cars</span>
              </Link>
              <Link to={'/cars'}>
                <span>Cars</span>
              </Link>
              <Link to={'/cars'}>
                <span>Cars</span>
              </Link>
              <Link to={'/cars'}>
                <span>Cars</span>
              </Link>
              <Link to={'/cars'}>
                <span>Cars</span>
              </Link>
              <Link to={'/cars'}>
                <span>Cars</span>
              </Link>
            </div> */}
        </div>

        <div className={classes.location}>
          <h2>Service Available In Cities</h2>
          <div className={classes.locationcolumn}>
            <ul>
              <li>Car Rental In Bangalore</li>
              <li>Car Rental In Hyderabad</li>
              <li>Car Rental In Mumbai</li>
              <li>Car Rental In Delhi</li>
              <li>Car Rental In Chennai</li>
              <li>Car Rental In Pune</li>
              <li>Car Rental In Mangalore</li>
              <li>Car Rental In Dombivili</li>
              <li>Car Rental In Palava</li>
              <li>Car Rental In Thane</li>
              <li>Car Rental In Amritsar</li>
            </ul>
            <ul>
              <li>Car Rental In Kolkata</li>
              <li>Car Rental In Ahmedabad</li>
              <li>Car Rental In Bhubaneswar </li>
              <li>Car Rental In Chandigarh</li>
              <li>Car Rental In Coimbatore</li>
              <li>Car Rental In Jaipur</li>
              <li>Car Rental In Kochi</li>
              <li>Car Rental In Nashik</li>
              <li>Car Rental In Madurai</li>
              <li>Car Rental In Ghaziabad</li>
              <li>Car Rental In Merrut</li>
            </ul>
            <ul>
              <li>Car Rental In Goa</li>
              <li>Car Rental In Lucknow</li>
              <li>Car Rental In Bhopal</li>
              <li>Car Rental In Guwahati</li>
              <li>Car Rental In Indore</li>
              <li>Car Rental In Cochin</li>
              <li>Car Rental In Mysore</li>
              <li>Car Rental In Modinagar</li>
              <li>Car Rental In Muradnagar</li>
              <li>Car Rental In Gurugram</li>
              <li>Car Rental In Noida</li>
            </ul>
            <ul>
              <li>Car Rental In Nagpur</li>
              <li>Car Rental In Siliguri</li>
              <li>Car Rental In Trichy</li>
              <li>Car Rental In Vadodara</li>
              <li>Car Rental In Vijaywada</li>
              <li>Car Rental In Vijag</li>
              <li>Car Rental In Udupi</li>
              <li>Car Rental In Vishakapatnam </li>
              <li>Car Rental In Udaipur</li>
              <li>Car Rental In Jodhpur</li>
              <li>Car Rental In Haridwar</li>
              <li>Car Rental In Rishikesh</li>
            </ul>
          </div>
        </div>
      </div>

    </footer>
  );
}

export default Footer;
