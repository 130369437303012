import "./dailyRentalsCarsListPage.scss";
import React from "react";

import { useEffect, useContext } from "react";
import { GetDailyRentalContext } from "../context/getdailyRentals-context";
import { DailyRentalCarCard } from "../components/carsBookingComponent/DailyRentalCarCard";
import { CardSkeleton } from "../components/cardSkeleton/CardSkeleton";
// import { Loading } from "../components/loading/loading";

export function DailyRentalsCarsListPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { getDailyRentalsList, setGetDailyRentalsList } = useContext(
    GetDailyRentalContext
  );

  useEffect(() => {
    if (getDailyRentalsList && Object.keys(getDailyRentalsList).length !== 0) {
      localStorage.setItem(
        "getDailyRentalsList",
        JSON.stringify(getDailyRentalsList)
      );
    } else {
      setGetDailyRentalsList(
        JSON.parse(localStorage.getItem("getDailyRentalsList"))
      );
    }
  }, [getDailyRentalsList]);

  return (
    <>
      {" "}
      {getDailyRentalsList === null ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: ["1.5rem"],
            marginBottom: ["15rem"],
            marginTop: ["8rem"],
          }}
        >
          {" "}
          <CardSkeleton cards={24} />
        </div>
      ) : (
        <div className="getDailyRentalsContainer">
          {getDailyRentalsList?.map((car, idx) => {
            return <DailyRentalCarCard car={car} key={idx} />;
          })}
        </div>
      )}
    </>
  );
}
