import React from "react";
import { useState, useEffect } from "react";
import classes from "./Brands.module.css";
import Card from "../UI/Card";

const brands = [
  {
    id: 1,
    src: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/wdc0xuodozghdbpq23v5",
  },
  {
    id: 2,
    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQk2dutShzV_dU7AaQ-jLs2p76U-WPTsc2NUrUDUGAFkw&s",
  },
  {
    id: 3,
    src: "https://media-exp1.licdn.com/dms/image/C4E0BAQEfgt3FTLDTRg/company-logo_200_200/0/1519884154917?e=2159024400&v=beta&t=N-2N0KC4-njG5O1IwI-y5-ktUKHxMuJmSYd5HExkwK0",
  },
  {
    id: 4,
    src: "https://www.wowcarz.in/resources/victoria/images/wowcarz-logo.png",
  },
  {
    id: 5,
    src: "https://play-lh.googleusercontent.com/DZvXCKxZhMPrhGv8Gk699So1UuFUeol6WnmlCrEaVH02C1-ODlPMPtm8Mc6EEI8iT3s",
  },
  {
    id: 6,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/LetzRent%20App%20Images%2FBrand%20Logo%2FKaryana%20Car%20Rental.png?alt=media&token=a8ffdc5f-d157-403c-8d49-9c911e54fcdc",
  },
  {
    id: 7,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/LetzRent%20App%20Images%2FBrand%20Logo%2FCarronrent.png?alt=media&token=045d4afd-a065-4554-b1bc-85130149b07e",
  },
  {
    id: 8,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/LetzRent%20App%20Images%2FBrand%20Logo%2FWhatsApp%20Image%202022-08-04%20at%2010.45.13%20PM.jpeg?alt=media&token=e3c00d49-eb95-40db-b795-f42688ceef7c",
  },
];

function Brands() {
  const [currentBrandIndex, setCurrentBrandIndex] = useState(0);

  // Use useEffect to update the current brand index every few seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBrandIndex(
        (currentIndex) => (currentIndex + 1) % brands.length
      );
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={classes.brand}>
      <h2 className={classes.brandTitle}>Compare And Choose From</h2>

      <div className={classes.brandContainer}>
        {brands.map((brand, index) => (
          <Card
            key={brand.id}
            className={index === currentBrandIndex ? classes.activeCard : ""}
          >
            <img
              src={brand.src}
              alt="brandImg"
              className={classes.brandImg}
              loading="lazy"
            />
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Brands;
